var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"VehicleSearch"}},[_vm._m(0),_c('div',{staticClass:"container-fluid",attrs:{"id":"Search"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-6 pl-0"},[_c('div',{staticClass:"pb-3 pb-lg-1 mt-0 mt-lg-3 pt-3 pt-lg-0",attrs:{"id":"Compare"}},[_vm._m(1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-6 mt-0 order-2 order-lg-1"},[_c('vehicle-search-form')],1),_vm._m(2)])])]),_c('div',{staticClass:"col-12 col-lg-6 pl-0 pr-0"},[_c('div',{staticClass:"container-fluid pb-3 pb-lg-1 mt-0 mt-lg-3",attrs:{"id":"Choose"}},[_vm._m(3),_c('div',{staticClass:"row pt-2"},[_c('div',{staticClass:"col-12"},[_c('VehicleYMMSSelector',{model:{value:(_vm.selectedVehicle),callback:function ($$v) {_vm.selectedVehicle=$$v},expression:"selectedVehicle"}})],1)]),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col text-right"},[_c('button',{staticClass:"button btn tertiary",attrs:{"disabled":!_vm.selectedVehicle},on:{"click":_vm.selectVehicle}},[_vm._m(4)])])])])])])]),_vm._m(5),_c('FeaturedVehiclesSection',{attrs:{"sectionIndex":0}}),_c('TopArticles'),_vm._l((_vm.featuredVehicles.slice(1)),function(category,idx){return _c('FeaturedVehiclesSection',{key:idx,attrs:{"sectionIndex":idx + 1}})})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid text-center",attrs:{"id":"Header"}},[_c('div',{staticClass:"row d-none d-lg-block"},[_c('div',{staticClass:"col"},[_c('img',{staticClass:"img-fluid",attrs:{"id":"Logo","src":require("Images/logo_large.png"),"alt":"electric driver logo"}})])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h3',[_vm._v("The fun & easy way to shop for an electric vehicle")])])]),_c('div',{staticClass:"row pt-2"},[_c('div',{staticClass:"col"},[_c('a',{staticClass:"button btn big secondary",attrs:{"href":"#Search"}},[_vm._v("Compare EVs Now "),_c('span',{staticClass:"accent"},[_vm._v("»")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row text-left pt-1"},[_c('div',{staticClass:"col"},[_c('h2',{staticClass:"compare-subheader"},[_vm._v("Find your EV: Needs-Based Search™")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-6 order-1 order-lg-2",attrs:{"id":"CompareChecklist"}},[_c('p',[_vm._v("We search and rank every EV on the market based on what matters to you. Find your perfect match, it's easy & fun!")]),_c('ul',{staticClass:"pl-4"},[_c('li',[_vm._v("Free")]),_c('li',[_vm._v("Unbiased")]),_c('li',[_vm._v("Personalized")]),_c('li',[_vm._v("Compare Models")]),_c('li',[_vm._v("Easy-to-understand Results")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row text-left pt-1"},[_c('div',{staticClass:"col"},[_c('h2',{staticClass:"compare-subheader"},[_vm._v("Research a Specific EV")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v("View "),_c('span',{staticClass:"accent"},[_vm._v("»")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row text-center d-none d-lg-flex",attrs:{"id":"NavigationDivider"}},[_c('div',{staticClass:"col-4"},[_c('img',{attrs:{"src":require("Images/logo_icon.svg")}}),_c('a',{attrs:{"href":"https://electricdriver.co/articles/"}},[_vm._v("EV Tips, Tricks & Advice »")])]),_c('div',{staticClass:"col-4"},[_c('img',{attrs:{"src":require("Images/logo_icon.svg")}}),_c('a',{attrs:{"href":"/Incentives"}},[_vm._v("Find Local Incentives »")])]),_c('div',{staticClass:"col-4"},[_c('img',{attrs:{"src":require("Images/logo_icon.svg")}}),_c('a',{attrs:{"href":"/ChargerFinder"}},[_vm._v("Charging Station Map »")])])])
}]

export { render, staticRenderFns }