<template>
  <div id="VehicleSearch">
    <div id="Header" class="container-fluid text-center">
      <div class="row d-none d-lg-block">
        <div class="col">
          <img id="Logo" src="~Images/logo_large.png" alt="electric driver logo" class="img-fluid" />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h3>The fun &amp; easy way to shop for an electric vehicle</h3>
        </div>
      </div>
      <div class="row pt-2">
        <div class="col"><a class="button btn big secondary" href="#Search">Compare EVs Now
            <span class="accent">&raquo;</span>
          </a></div>
      </div>
    </div>

    <div id="Search" class="container-fluid">
      <div class="row">
        <div class="col-12 col-lg-6 pl-0">
          <div id="Compare" class="pb-3 pb-lg-1 mt-0 mt-lg-3 pt-3 pt-lg-0">
            <div class="row text-left pt-1">
              <div class="col">
                <h2 class="compare-subheader">Find your EV: Needs-Based Search&trade;</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-lg-6 mt-0 order-2 order-lg-1">
                <vehicle-search-form />
              </div>
              <div id="CompareChecklist" class="col-lg-6 order-1 order-lg-2">
                <p>We search and rank every EV on the market based on what matters to you. Find your perfect match, it's
                  easy &amp; fun!</p>
                <ul class="pl-4">
                  <li>Free</li>
                  <li>Unbiased</li>
                  <li>Personalized</li>
                  <li>Compare Models</li>
                  <li>Easy-to-understand Results</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 pl-0 pr-0">
          <div id="Choose" class="container-fluid pb-3 pb-lg-1 mt-0 mt-lg-3">
            <div class="row text-left pt-1">
              <div class="col">
                <h2 class="compare-subheader">Research a Specific EV</h2>
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-12">
                <VehicleYMMSSelector v-model="selectedVehicle" />
              </div>
            </div>
            <div class="row mt-2">
              <div class="col text-right">
                <button class="button btn tertiary" @click="selectVehicle" :disabled="!selectedVehicle">
                  <div>View <span class="accent">&raquo;</span></div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="NavigationDivider" class="row text-center d-none d-lg-flex">
      <div class="col-4"><img src="~Images/logo_icon.svg" /><a href="https://electricdriver.co/articles/">EV Tips, Tricks
          &amp; Advice&nbsp;&raquo;</a></div>
      <div class="col-4"><img src="~Images/logo_icon.svg" /><a href="/Incentives">Find Local Incentives&nbsp;&raquo;</a>
      </div>
      <div class="col-4"><img src="~Images/logo_icon.svg" /><a href="/ChargerFinder">Charging Station Map&nbsp;&raquo;</a>
      </div>
    </div>
    <FeaturedVehiclesSection :sectionIndex="0" />
    <TopArticles />   
    <FeaturedVehiclesSection :sectionIndex="idx + 1" v-for="(category, idx) in featuredVehicles.slice(1)" :key="idx"/>

  </div>
</template>

<script>
import common from "Mixins/common";
const TopArticles = () => import("Components/VehicleSearch/TopArticles");
const VehicleYMMSSelector = () => import("Components/VehicleYMMSSelector");
const VehicleSearchForm = () =>
  import("Components/VehicleSearch/VehicleSearchForm");
const FeaturedVehiclesSection = () => import("Components/VehicleSearch/FeaturedVehiclesSection");

export default {
  metaInfo: {
    title: "Personalized electric vehicle recommendations and comparisons",
  },
  data() {
    return {
      selectedVehicle: null,
      showDetail: true
    };
  },
  mounted() {
    this.$store.dispatch("fetchAllYMMS");
    this.$store.dispatch("fetchAllBodyStyles");
    this.$store.dispatch("fetchFeaturedVehicles");
  },
  methods: {
    selectVehicle() {
      this.$router.push({
        path: `detail/${this.selectedVehicle.YMMS_Desc}`,
      });
    },
  },
  computed: {
    featuredVehicles() {
      return this.$store.state.featuredVehicles;
    },
  },
  mixins: [common],
  components: {
    VehicleYMMSSelector,
    TopArticles,
    VehicleSearchForm,
    FeaturedVehiclesSection,
},
};
</script>

<style scoped lang="scss">
@import "bootstrap/scss/forms";

#Logo {
  height: 49px;
}

#Header {
  padding-top: 57px;
  padding-bottom: 31px;
  background: url("~Images/ev_charging_bg.webp") black no-repeat;

  @include media-breakpoint-down(md) {
    padding-top: 5px;
    padding-bottom: 20px;
  }

  h3 {
    padding-top: 15px;
    color: white;
    font-family: $primary-header-font-family;
    font-weight: 900;
    font-size: 1.25rem;

    @include media-breakpoint-down(md) {
      font-size: 1.5rem;
      padding-bottom: 10px;
    }
  }

  .button.btn.big {
    font-size: 1.25rem;
    font-weight: 800;
  }
}

#Search {
  background-color: white;
}

#Compare {
  background-color: $striped-row-bg-color;
  margin: 12px 0 21px 0;
  padding-left: 30px;
  border-radius: 0 0 9px 9px;


  .compare-subheader {
    color: $link-color-dark;
    font-size: 1.5rem;
    font-weight: 900;
  }
}

#Choose,
#Compare {
  box-sizing: border-box;
  box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.2);

  @include media-breakpoint-up(lg) {
    height: 320px;
  }
}

#CompareChecklist {
  color: black;
  font-size: 0.9375rem;

  p {
    font-weight: 800;
  }

  li {
    list-style-image: url("~Images/check.webp");
  }
}

#Choose {
  border-radius: 0 0 9px 9px;
  box-sizing: border-box;
  box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.2);
  margin: 12px 0 21px 0;

  h2 {
    color: #333333;
    font-size: 1.4375rem;
  }

  @include media-breakpoint-down(md) {
    border-radius: 0;
    box-shadow: none;
  }
}


::v-deep .ymms-selector {
  select {
    @extend .form-control, .form-control-sm;
    margin-bottom: 25px;
    min-width: 200px;
  }

  option {
    padding-top: 20px;
  }
}

#About {
  color: black;
  background-color: #1d7fda;
  padding-top: 100px;
  padding-bottom: 100px;

  #Description {
    p {
      padding-top: 10px;
    }
  }

  #Detail {
    color: white;

    h3 {
      font-size: 1.25rem;
      font-weight: normal;
    }

    p {
      margin-top: -8px;
    }
  }
}

button.btn {
  font-size: 1.062rem;
  padding: 4px 15px 4px 15px;
}

#NavigationDivider {
  height: 69px;
  font-size: 1.0625;

  font-weight: 800;

  img {
    height: 40px;
    position: relative;
    top: -2px;
  }

  div {
    a {
      color: white !important;
    }

    padding-top: 18px;

    &:first-child {
      background-color: #27a7a0;
    }

    &:nth-child(2) {
      background-color: #27a777;
    }

    &:last-child {
      background-color: #30ca34;
    }
  }
}

</style>
